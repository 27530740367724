<template>
  <v-container
    id="Homepage"
    fluid
    tag="section"
  >
    <v-row
      justify="center"
      style="pa-5"
    >
      <!-- Establishing two separate columns for this row, each with length 6. -->
      <v-col
        cols="12"
        lg="6"
      >
        <!-- Profile Card. -->
        <base-material-card
          class="v-card-profile mb-n12"
          avatar="/img/profile.png"
          height="100%"
        >
          <!-- Fills in the information below the avatar icon. pa-0 removes the padding. -->
          <v-card-text
            class="text-center pt-0"
          >
            <!-- This is the title right underneath the avatar. h1 refers to a typography style.-->
            <h1>
              Graduate Research Assistant at Texas A&M University
            </h1>
            <!-- Brief introduction. -->
            <p
              class="pt-5"
            >
              <font
                size="4.5"
              >
                Hi there! My name is Wei Trinh, and I am a Ph.D. student at Texas A&M University. My current research interests involve
                power system dynamics and simulations, with a focus on modal analysis techniques and understanding the nature of system modes
                in the electric grid. I also work on a variety of other topics, unrelated to my main work. Here you'll find projects that
                I'm currently working on, both personal and research related. You can use the navigation bar on the left to find out more.
              </font> <br> <br>
              <font
                size="2.5"
              >
                This website was built using VueJS and the Material Dashboard template provided by
                <a
                  href="https://www.creative-tim.com/"
                  target="_blank"
                >
                  Creative Tim.
                </a>
              </font>
            </p>
          </v-card-text>
        </base-material-card>
      </v-col>
      <!-- Establishing two separate columns for this row, each with length 6. -->
      <v-col
        cols="12"
        lg="6"
      >
        <!-- Profile Card. -->
        <base-material-card
          title="Contact Information"
          height="100%"
          color="#454545"
          class="pb-n5"
        >
          <p
            class="text-center"
          >
            <font
              size="4.5"
            >
              <!-- <br> gives a line break. -->
              <br>Wisenbaker Engineering Building 308J
              <br>188 Bizzell Street
              <br>College Station, TX 77480
            </font>
          </p>
          <v-row>
            <!-- <a> is an anchor, and is used to hyperlink text. This one hyperlinks an email. -->
            <v-col
              cols="6"
              lg="3"
              class="text-center"
            >
              <a
                href="mailto:weit1@tamu.edu"
                target="_blank"
                style="text-decoration: none"
              >
                <v-icon
                  large
                >
                  mdi-email
                </v-icon>
                <br>E-mail
              </a>
            </v-col>
            <!-- <a> is an anchor, and is used to hyperlink text. This one hyperlinks Google Scholar. -->
            <v-col
              cols="6"
              lg="3"
              class="text-center"
            >
              <a
                href="https://scholar.google.com/citations?user=GM8LLcAAAAAJ&hl=en"
                target="_blank"
                style="text-decoration: none"
              >
                <v-icon
                  large
                >
                  mdi-school
                </v-icon>
                <br> Google Scholar
              </a>
            </v-col>
            <!-- <a> is an anchor, and is used to hyperlink text. This one hyperlinks Github. -->
            <v-col
              cols="6"
              lg="3"
              class="text-center"
            >
              <a
                href="https://github.com/Weifarers"
                target="_blank"
                style="text-decoration: none"
              >
                <v-icon
                  large
                >
                  mdi-github
                </v-icon>
                <br> Github
              </a>
            </v-col>
            <!-- <a> is an anchor, and is used to hyperlink text. This one hyperlinks LinkedIn. -->
            <v-col
              cols="6"
              lg="3"
              class="text-center"
            >
              <a
                href="https://www.linkedin.com/in/wei-trinh-40236999/"
                target="_blank"
                style="text-decoration: none"
              >
                <v-icon
                  large
                >
                  mdi-linkedin
                </v-icon>
                <br> LinkedIn
              </a>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
    <!-- This starts the next part of the homepage, which contains the updates timeline. -->
    <v-row
      justify="left"
      class="pt-5"
    >
      <v-col
        cols="12"
      >
        <v-card
          class="mx-auto"
        >
          <v-card-title class="info">
            <h3 class="text-h3 text-center grow font-weight-bold white--text">
              Updates
            </h3>
          </v-card-title>
          <v-timeline
            dense
          >
            <!-- June 2021 -->
            <v-timeline-item
              small
            >
              <font
                size="4.5"
                class="font-weight-bold"
              >
                June 2021
              </font>
              <font
                size="4.5"
              >
                I was a recipient of the Thomas W. Powell ’62 and Powell Industries Inc. Fellowship for the 2021 academic year!
                Thank you to the EPG at the TAMU ECEN Department very much for this fellowship!
              </font>
            </v-timeline-item>
            <!-- May 2021 -->
            <v-timeline-item
              small
            >
              <font
                size="4.5"
                class="font-weight-bold"
              >
                May 2021
              </font>
              <font
                size="4.5"
              >
                Website has been revamped!
              </font>
            </v-timeline-item>
            <!-- April 2021 -->
            <v-timeline-item
              small
            >
              <font
                size="4.5"
                class="font-weight-bold"
              >
                April 2021
              </font>
              <font
                size="4.5"
              >
                Presented "Sensitivity of Modes from Modal Analysis of Electric Grids" virtually at KPEC 2021.
              </font>
            </v-timeline-item>
            <v-timeline-item
              small
            >
              <font
                size="4.5"
                style="padding-left: 86px"
              >
                Presented "Considerations in the Initialization of Power Flow Solutions from Dynamic Simulation Snapshots" virtually at NAPS 2021.
              </font>
            </v-timeline-item>
            <!-- March 2021 -->
            <v-timeline-item
              small
            >
              <font
                size="4.5"
                class="font-weight-bold"
              >
                March 2021
              </font>
              <font
                size="4.5"
              >
                Sat in on the "ECEN Power and Energy Grad School Information Session" virtually as a panelist, to discuss graduate school at
                Texas A&M, specifically to students looking to pursue power-related research.
              </font>
            </v-timeline-item>
            <!-- February 2021 -->
            <v-timeline-item
              small
            >
              <font
                size="4.5"
                class="font-weight-bold"
              >
                February 2021
              </font>
              <font
                size="4.5"
              >
                TPEC 2021 was successfully run virtually via the Gather platform! A summary of TPEC 2021 can be found
                <a
                  href="http://tpec.engr.tamu.edu/2021-tpec-summary/"
                  target="_blank"
                  style="text-decoration: none"
                >
                  <font
                    size="4.5"
                  >
                    here.
                  </font>
                </a>
              </font>
            </v-timeline-item>
            <!-- May 2020 -->
            <v-timeline-item
              small
            >
              <font
                size="4.5"
                class="font-weight-bold"
              >
                May 2020
              </font>
              <font
                size="4.5"
              >
                I was a recipient of the Thomas W. Powell ’62 and Powell Industries Inc. Fellowship for the 2020 academic year!
                Thank you to the EPG at the TAMU ECEN Department very much for this fellowship!
              </font>
            </v-timeline-item>
            <!-- February 2020 -->
            <v-timeline-item
              small
            >
              <font
                size="4.5"
                class="font-weight-bold"
              >
                February 2020
              </font>
              <font
                size="4.5"
              >
                TPEC 2020 was successfully run at Texas A&M University! A summary of TPEC 2020 can be found
                <a
                  href="http://tpec.engr.tamu.edu/2020-tpec-summary/"
                  target="_blank"
                  style="text-decoration: none"
                >
                  <font
                    size="4.5"
                  >
                    here.
                  </font>
                </a>
              </font>
            </v-timeline-item>
            <!-- October 2019 -->
            <v-timeline-item
              small
            >
              <font
                size="4.5"
                class="font-weight-bold"
              >
                October 2019
              </font>
              <font
                size="4.5"
              >
                Presented "How Math Can Tell You Why Your Lights Won't Turn On" at the
                Applied Mathematics Undergraduate SEminar (AMUSE) series at Texas A&M University.
              </font>
            </v-timeline-item>
            <!-- September 2019 -->
            <v-timeline-item
              small
            >
              <font
                size="4.5"
                class="font-weight-bold"
              >
                September 2019
              </font>
              <font
                size="4.5"
              >
                Sat in on the "Ask ECE-GSA Panel" at Texas A&M University as a panelist.
                I answered general graduate student questions regarding research, student life, and classes.
              </font>
            </v-timeline-item>
            <!-- May 2019 -->
            <v-timeline-item
              small
            >
              <font
                size="4.5"
                class="font-weight-bold"
              >
                May 2019
              </font>
              <font
                size="4.5"
              >
                Presented "A Comparison of Dynamic Mode Decomposition and Iterative Matrix Pencil Method for Power System Modal Analysis" at
                the IEEE SGSMA 2019.
              </font>
            </v-timeline-item>
            <v-timeline-item
              small
            >
              <font
                size="4.5"
                style="padding-left: 81px"
              >
                I was a recipient of the Thomas W. Powell ’62 and Powell Industries Inc. Fellowship for the 2019 academic year!
                Thank you to the EPG at the TAMU ECEN Department very much for this fellowship!
              </font>
            </v-timeline-item>
            <!-- April 2019 -->
            <v-timeline-item
              small
            >
              <font
                size="4.5"
                class="font-weight-bold"
              >
                April 2019
              </font>
              <font
                size="4.5"
              >
                Presented "An Overview of Modal Analysis Techniques for Large Scale Power Systems" for the EPG
                weekly seminar series.
              </font>
            </v-timeline-item>
            <!-- March 2019 -->
            <v-timeline-item
              small
            >
              <font
                size="4.5"
                class="font-weight-bold"
              >
                March 2019
              </font>
              <font
                size="4.5"
              >
                Sat in on the "ECE Graduate Student Panel" for HKN undergraduates as a panelist.
                I looked to answer questions from undergraduates about applying to graduate school and graduate student life.
              </font>
            </v-timeline-item>
            <v-timeline-item
              small
            >
              <font
                size="4.5"
                style="padding-left: 99px"
              >
                Sat in on the "ECE-GSA Q&A Panel" for new ECEN graduate students as a panelist.
                I answered questions regarding what classes to take, professors to work for, and general graduate student life.
              </font>
            </v-timeline-item>
            <!-- February 2019 -->
            <v-timeline-item
              small
            >
              <font
                size="4.5"
                class="font-weight-bold"
              >
                February 2019
              </font>
              <font
                size="4.5"
              >
                TPEC 2019 was successfully run at Texas A&M University! A summary of TPEC 2019 can be found
                <a
                  href="http://tpec.engr.tamu.edu/2019-tpec-summary/"
                  target="_blank"
                  style="text-decoration: none"
                >
                  <font
                    size="4.5"
                  >
                    here.
                  </font>
                </a>
              </font>
            </v-timeline-item>
            <!-- January 2019 -->
            <v-timeline-item
              small
            >
              <font
                size="4.5"
                class="font-weight-bold"
              >
                January 2019
              </font>
              <font
                size="4.5"
              >
                My paper entitled "Iterative Matrix Pencil Method for Power System Modal Analysis" was accepted
                and presented at the 52nd Annual HICSS!
              </font>
            </v-timeline-item>
          </v-timeline>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Homepage',
  }
</script>
